import styled from 'styled-components';
import { isTi, isSga } from 'shared/utils/targetApplication';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import { isStandalonePWA } from '../../utils/mobileSupported';

const isMarginRequired = isTi || isSga;

const getItemMaxHeight = (itemsLength, isSmallResolution) => {
  return isSmallResolution
    ? itemsLength === 4
      ? '18%'
      : itemsLength === 5
        ? '15%'
        : 'auto'
    : itemsLength === 4
      ? '20%'
      : itemsLength === 5
        ? '16%'
        : 'auto';
};

const BlackOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({ isVisible }) => (isVisible ? 'rgba(0,0,0,0.5)' : 'transparent')};
  z-index: 201;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  transition: background-color 0.3s ease;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: ${({ isBlockedForMissionsTutorial }) => isBlockedForMissionsTutorial ? 200 : isMobile && !isTablet ? 1390 : 390};
  left: ${({ menuWidth }) => -menuWidth}px;
  transform: ${({ isMenuVisible, menuWidth }) => (isMenuVisible ? `translateX(${menuWidth}px)` : 'translateX(0)')};
  transition: transform 0.3s ease-in-out;
  align-items: center;
  height: 100%;
  width: 40vw;

  @media only screen and (max-height: 768px) and (max-width: 1366px) {
    width: 42vw;
  }

  @media only screen and (max-height: 720px) {
    width: 32vw;
    @media (display-mode: fullscreen) {
      width: 40vw;
    }
  }
`;

const MenuContainer = styled.div`
  display: inline;
  z-index: 390;
  box-sizing: border-box;
  width: ${isIOS ? '33' : '31'}vw;
  justify-content: space-around;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  box-shadow: inset 0px 0 40px #000000;

  @media only screen and (max-height: 720px) {
    ${isMarginRequired && 'margin-top: 1.5vh;'}
    width: 25vw;
    padding: 0.5rem 0;
    @media (display-mode: fullscreen) {
      width: ${isIOS ? '33' : '31'}vw;
    }
  }

  ${({ theme }) => theme};
  ${({ hasSportsRoom }) => !hasSportsRoom && isTi && `height: 75vh`};
`;

const MenuItemContainer = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  display: flex;
  flex: 1;
  align-items: center;
  ${({ theme }) => theme}
`;

const MenuItemText = styled.h1`
  position: relative;
  display: inline;
  font-weight: 600;
  line-height: 5vh;
  text-align: left;

  ${!isMobile && `
    @media only screen and (max-width: 1280px) {
      font-size: 3.3vw;
    }

    @media only screen and (max-height: 720px) {
      font-size: 2vw;
      @media (display-mode: fullscreen) {
        font-size: 2.8vw;
      }
    }

    @media only screen and (max-width: 1300px) and (max-height: 720px) {
      font-size: 2vw;
    }
  `}

  span {
    position: relative;
    background: linear-gradient(0deg, #e7c377 0%, #e7c377 35%, #a1743d 50%, #fef7b9 51%, #e7c377 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-stroke: 1px #ffff8d;
    filter: saturate(160%);
  }

  &::before {
    content: attr(data-text);
    position: absolute;
    top: 1px;
    left: 0;
    z-index: -1;
    -webkit-text-stroke: 8px #000000;
    opacity: 0.5;
  }

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    color: rgba(0, 0, 0, 0.5);
    text-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
    -webkit-text-stroke: 5px #4c300b;
  }

  &::after {
    color: transparent;
  }

  ${({ theme }) => theme};
`;

const ItemButton = styled.img.attrs(({ isActive }) => ({
  src: isActive ? assetSource(ASSET_KEY.SIDEBAR_BUTTON_HIGHLIGHTED) : assetSource(ASSET_KEY.SIDEBAR_BUTTON),
}))`
  width: ${(isTablet || isStandalonePWA) ? '29' : isMobile ? '23' : isIOS ? '31' : '30'}vw;
  ${({ itemsLength }) =>
    isStandalonePWA && !isTablet
      ? `height: 23%;`
      : `max-height: ${getItemMaxHeight(itemsLength)};`
  }

 ${!isMobile && `
    @media (max-height: 720px) {
      width: 23vw;
      @media (display-mode: fullscreen) {
        width: ${isIOS ? '31' : '30'}vw;
      }
    }
    @media (max-height: 640px) {
      max-height: 18%;
      max-height: ${({ itemsLength }) => getItemMaxHeight(itemsLength, true)};
    }
  `}
  
  ${({ theme }) => theme};
`;

const Toggler = styled.img.attrs(() => ({
  src: assetSource(ASSET_KEY.SIDEBAR_ARROW_BUTTON),
}))`
  height: ${(isTablet || isStandalonePWA) ? '7' : isIOS ? '5' : '6'}vw;
  margin-left: ${({ isMenuVisible }) => (!isIOS && isMenuVisible ? '10px' : '2.5vw')};
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transform: ${({ isMenuVisible }) => (isMenuVisible ? 'rotate(180deg)' : 'rotate(0)')};
  position: ${isIOS ? 'absolute' : 'relative'};
  right: ${({ isMenuVisible }) => ((isTablet || isStandalonePWA) && isMenuVisible ? '1' : isIOS && isMenuVisible ? '3' : isTablet ? '-1' : '-2.5')}vw;
`;

export { BlackOverlay, Container, MenuContainer, MenuItemContainer, MenuItemText, ItemButton, Toggler };

