import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _getScreenByModalType;
import screens from '../../../screens';
import { ACTION_COMMANDS_MODAL_TYPE, ACTION_COMMANDS_TOOLTIP_TYPE, ACTION_COMMANDS_TYPE } from '../constants';
import { handleLinkAccountTooltip, handleRewardCenterTooltip, handleEventsTooltip } from './tooltip';
export var getScreenByModalType = (_getScreenByModalType = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_getScreenByModalType, ACTION_COMMANDS_MODAL_TYPE.WELCOME_BONUS, screens.WelcomeBonus), ACTION_COMMANDS_MODAL_TYPE.DAILY_WHEEL_ONBOARDING, screens.DailySpinOnboarding), ACTION_COMMANDS_MODAL_TYPE.DAILY_WHEEL, screens.DailySpin), ACTION_COMMANDS_MODAL_TYPE.ALLOW_MARKETING_EMAILS, screens.DynamicPopups), ACTION_COMMANDS_MODAL_TYPE.EMAIL_VERIFICATION, screens.DynamicPopups), ACTION_COMMANDS_MODAL_TYPE.ALLOW_NOTIFICATIONS, screens.DynamicPopups), ACTION_COMMANDS_MODAL_TYPE.SAVE_YOUR_PROGRESS, screens.DynamicPopups), ACTION_COMMANDS_MODAL_TYPE.WELCOME_NEWS, screens.News), ACTION_COMMANDS_MODAL_TYPE.LIMTED_TIME_OFFER, screens.LimitedTimeOffer), ACTION_COMMANDS_MODAL_TYPE.PASSWORD_PROMPT, screens.GuestAccountUpgrade), _defineProperty(_getScreenByModalType, "default", undefined));
export var getScreenByActionType = _defineProperty({}, ACTION_COMMANDS_TYPE.DISPLAY_EVENTS_TUTORIAL, screens.Events);
export var getTooltipByType = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ACTION_COMMANDS_TOOLTIP_TYPE.CARD_LINK, handleLinkAccountTooltip), ACTION_COMMANDS_TOOLTIP_TYPE.REWARD_CENTER, handleRewardCenterTooltip), ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_ABOUT_TO_END, handleEventsTooltip), ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_COMPLETED, handleEventsTooltip), ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_EXPIRED, handleEventsTooltip), "default", function _default() {});
export var isDailyWheelModal = function isDailyWheelModal(modalType) {
  return modalType === ACTION_COMMANDS_MODAL_TYPE.DAILY_WHEEL || modalType === ACTION_COMMANDS_MODAL_TYPE.DAILY_WHEEL_ONBOARDING;
};