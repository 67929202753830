import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import screens from '..';
import Emitter from '../../services/EmitterService';
import { EVENT_EMITTER_TYPE } from '../../constants';
import QueryParamProxy from '../../utils/queryParamProxy';
import { useMemo } from 'react';
import { ACTION_COMMANDS_TYPE } from '../../api/ActionCommandsInterface/constants';
import { useEffect } from 'react';
import { getText, TEXT_KEY } from '../../utils/localization';
var useGuestAccountUpgrade = function useGuestAccountUpgrade() {
  var action = useSelector(function (state) {
    var _state$actionCommands;
    return (_state$actionCommands = state.actionCommands) === null || _state$actionCommands === void 0 || (_state$actionCommands = _state$actionCommands.actions) === null || _state$actionCommands === void 0 ? void 0 : _state$actionCommands.find(function (action) {
      var _action$payload;
      return (action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.screen) === screens.Home;
    });
  });
  var _useMemo = useMemo(function () {
      var _action$modal;
      return {
        modal: action.modal,
        uuid: action.uuid,
        onClickAction: (_action$modal = action.modal) === null || _action$modal === void 0 || (_action$modal = _action$modal.buttons) === null || _action$modal === void 0 || (_action$modal = _action$modal[0]) === null || _action$modal === void 0 ? void 0 : _action$modal.onClickAction
      };
    }, [action]),
    modal = _useMemo.modal,
    uuid = _useMemo.uuid,
    onClickAction = _useMemo.onClickAction;
  var formikValidation = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSumbitHandler,
    valideOnMount: true
  });
  function onSumbitHandler(requestData) {
    Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
      actionType: onClickAction.actionType,
      data: {
        modal: modal,
        onClickAction: onClickAction,
        dismissModalHandler: function dismissModalHandler() {
          return QueryParamProxy.dismissModal();
        },
        requestData: requestData
      }
    });
  }
  useEffect(function () {
    return function () {
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND,
        data: {
          uuid: uuid
        }
      });
    };
  }, [uuid]);
  return {
    formikValidation: formikValidation
  };
};
var initialValues = {
  password: '',
  confirmPassword: ''
};
var validationSchema = yup.object({
  password: yup.string().min(6, function () {
    return getText(TEXT_KEY.PASSWORD_LENGTH);
  }).required(function () {
    return getText(TEXT_KEY.PASSWORD_REQUIRED);
  }),
  confirmPassword: yup.string().oneOf([yup.ref('password')], function () {
    return getText(TEXT_KEY.PASSWORDS_DONT_MATCH);
  }).required(function () {
    return getText(TEXT_KEY.CONFIRM_PASSWORD);
  })
});
export default useGuestAccountUpgrade;