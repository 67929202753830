import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';
import { isMobile, isTablet } from 'react-device-detect';

const BattlesBegin = ({ battlesAssets, battlesStarted, handleBattlesStarted }) => {
  return (
    <Container background={battlesAssets.BATTLES_BACKGROUND_SPLASH} battlesStarted={battlesStarted}>
      <BattlesLogo src={battlesAssets.BATTLES_SPLASH_LOGO} />
      <BattlesBeginButton
        label={getText(TEXT_KEY.LET_THE_BATTLES_BEGIN)}
        imageSource={assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)}
        onClick={handleBattlesStarted}
      />
    </Container>
  );
};

export default BattlesBegin;

const isMobileBrowser = isMobile && isNotStandaloneMobilePWA && !isTablet;

const Container = styled.div`
  display: ${({ battlesStarted }) => (battlesStarted ? 'none' : 'flex')};
  flex-direction: column;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 100;
  position: relative;
  overflow: hidden;
`;

const BattlesLogo = styled.img`
  height: ${isMobileBrowser ? 55 : 65}vh;
  object-fit: contain;
`;

const BattlesBeginButton = styled(Button).attrs({
  containerStyle: `
    width: ${isMobileBrowser ? 22 : 30}vw;
    `,
  imageStyle: `
    width: ${isMobileBrowser ? 22 : 30}vw;
    `,
  textStyle: `
    font-size: ${isMobileBrowser ? 1.5 : 2}vw;
    text-transform: uppercase;
    padding-bottom: 1%;
    `,
})``;

