import React from 'react';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import useGuestAccountUpgrade from 'shared/screens/GuestAccountUpgrade/useGuestAccountUpgrade';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import BoxyLayoutTextInputsWrapper from '../../components/TextInput/BoxyLayoutTextInputsWrapper';
import { TextInput } from '../../components/TextInput';

const GuestAccountUpgrade = () => {
  const { ResetPassword } = useThemeContext();
  const { formikValidation } = useGuestAccountUpgrade();
  const {
    isValid,
    isSubmitting,
    handleBlur,
    handleSubmit: submitForm,
    values,
    errors,
    status,
    handleChange,
  } = formikValidation;

  return (
    <ModalFormBody>
      <PageDescription>{getText(TEXT_KEY.GUEST_ACCOUNT_UPGRADE_DESCRIPTION)}</PageDescription>
      <FormWrapper className="recoverPasswordForm">
        <FormInputLabel>
          <BoxyLayoutTextInputsWrapper error={errors?.password}>
            <TextInput
              theme={ResetPassword.FormInputsFields}
              type="password"
              placeholder={getText(TEXT_KEY.GUEST_ACCOUNT_UPGRADE_NEW_PASSWORD)}
              value={values.password}
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
              boxErrorLayout
              error={errors?.password}
            />
          </BoxyLayoutTextInputsWrapper>
        </FormInputLabel>
        <FormInputLabel>
          <BoxyLayoutTextInputsWrapper error={errors?.confirmPassword}>
            <TextInput
              theme={ResetPassword.FormInputsFields}
              type="password"
              maxLength="50"
              placeholder={getText(TEXT_KEY.CONFIRM_PASSWORD)}
              value={values.confirmPassword}
              onChange={handleChange('confirmPassword')}
              onBlur={handleBlur('confirmPassword')}
              boxErrorLayout
              error={errors?.confirmPassword}
            />
          </BoxyLayoutTextInputsWrapper>
        </FormInputLabel>
      </FormWrapper>

      {status && <Status>{status}</Status>}
      <SumbitButton
        isValid={isValid}
        label={getText(TEXT_KEY.GUEST_ACCOUNT_UPGRADE_SAVE)}
        onClick={submitForm}
        disabled={!isValid || isSubmitting}
        isDebounce={true}
      />
    </ModalFormBody>
  );
};

export default GuestAccountUpgrade;

GuestAccountUpgrade.title = getText(TEXT_KEY.GUEST_ACCOUNT_UPGRADE_TITLE);
GuestAccountUpgrade.defaultHeader = true;
GuestAccountUpgrade.helpButton = true;

const ModalFormBody = styled.div`
  width: 100%;
  height: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const PageDescription = styled.p`
  text-align: center;
  margin: 1rem 0 !important;
`;

const Status = styled.div`
  color: red;
  margin-top: 1em;
`;

const SumbitButton = styled(Button).attrs(({ isValid }) => ({
  imageSource: isValid ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED),
  textStyle: `
  text-transform: uppercase;
  font-weight : 600;
  `,
}))`
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  font-weight: 700;
`;

const FormWrapper = styled.div`
  width: 30rem;
  gap: 1.5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const FormInputLabel = styled.div`
  display: flex;
  flex: 1;
  margin: 5px;
  flex-direction: column;
`;

