import React from 'react';
import { isMobile } from 'react-device-detect';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import styled from 'styled-components';

const LimitReachedScreen = props => {
  const { friendsInviteFeatureState, friendsInviteAssets } = props;
  return (
    <Container {...props}>
      <Header>
        <MessageTitle>{getText(TEXT_KEY.SOCIAL_LIMIT_REACHED_TITLE)}</MessageTitle>
        <Divider src={friendsInviteAssets?.SOCIAL_MESSAGES_DIVIDER} />
      </Header>
      <Body>
        <MessageDescription>
          {`${getText(TEXT_KEY.SOCIAL_LIMIT_REACHED_DESCRIPTION_LEFT)} (${
            friendsInviteFeatureState?.inviteLink?.maxRedeems
          }) ${getText(TEXT_KEY.SOCIAL_LIMIT_REACHED_DESCRIPTION_RIGHT)}`}
        </MessageDescription>
      </Body>
      <Footer src={friendsInviteAssets?.SOCIAL_LIMIT_REACHED_IMAGE} />
    </Container>
  );
};

export default LimitReachedScreen;

const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 99%;
  flex: 5;
  padding: ${isMobile ? 2 : 5}rem 0;
  display: ${({ limitReached, hasActiveState, friendsInviteAssets }) =>
    limitReached && hasActiveState && friendsInviteAssets ? 'flex' : 'none'};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex: 2;
`;

const Divider = styled.img`
  width: 100%;
  height: 9rem;
  position: relative;
  top: -0.5rem;
`;

const MessageTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: white;
`;

const Body = styled.div`
  width: 75%;
  flex: 4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
`;

const MessageDescription = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-top: 1rem;
  width: 100%;
`;

const Footer = styled.img`
  width: 84%;
  height: 14rem;
  position: absolute;
  bottom: 0.3rem;
  object-fit: cover;
`;

