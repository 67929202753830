import React from 'react';
import styled from 'styled-components';
import BannerItem from './BannerItem';

const Banner = ({ navigation, lobbyBanners, scrolling, updateScrollInstance, triggerScrollUpdate }) => {
  return (
    <Container>
      {lobbyBanners?.map((banner, index) => (
        <BannerItem
          key={index}
          banner={banner}
          navigation={navigation}
          scrolling={scrolling}
          updateScrollInstance={updateScrollInstance}
          triggerScrollUpdate={triggerScrollUpdate}
        />
      ))}
    </Container>
  );
};

export default Banner;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

