import React, { Component } from 'react';
import { SceneView } from '@react-navigation/core';
/** Screens */
import UpdateTerms from '../../screens/UpdateTerms/UpdateTerms';

/** Persistent Components */
import NavigationConstants from 'shared/components/LobbyOverlay/NavigationConstants';
import BottomBar from '../BottomBar';
import NavigationBar from '../NavigationBar';
import PinnedPanel from '../PinnedPanel';
import SideMenu from '../SideMenu';

/** Utility */
import ThemeContext from 'shared/context/ThemeContext';
import { tutorialPointerEvents } from 'shared/utils/tutorial';
import { BlackOverlay, MainSceneContainer, PinnedPanelContainer } from './styledComponents';

/** Notification Components */
import SLobbyOverlay from 'shared/components/LobbyOverlay';
import Utils from 'shared/utils';
import QueryParamProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';
import TimestampManagerComponent from '../TimestampManagerComponent';
import Toast from '../Toast';
import LobbyModal from './components/LobbyModal';
import LobbyTooltip from './components/LobbyTooltip';

export default class LobbyOverlay extends Component {
  state = {
    modal: undefined,
    showModal: false,
    isCoinsGlowAnimationStopped: true,
    isBlackLayoutVisible: false,
  };
  componentDidMount() {
    QueryParamProxy.addListener(QueryParamProxy.QUERY_PARAM_CHANGE, this.handleParamsChange);
  }

  componentWillUnmount() {
    clearInterval(this.setTimerInterval);
    QueryParamProxy.removeListener(QueryParamProxy.QUERY_PARAM_CHANGE, this.handleParamsChange);
  }

  startTimedBonusAnimation = () => {
    // starting animations
    this.setState({ isBlackLayoutVisible: true });
    setTimeout(() => {
      this.setState({ isCoinsGlowAnimationStopped: false });
    }, 1000);

    // setting timeouts for stopping them
    setTimeout(() => {
      this.setState({ isCoinsGlowAnimationStopped: true, isBlackLayoutVisible: false });
    }, 1900);
  };

  handleParamsChange = params => {
    if (params.hasOwnProperty(RouteParamConstants.PARAM_MODAL)) {
      this.setState({
        // IIFE, defines modal on the setState object if it should be changed.
        ...(() => {
          if (params[RouteParamConstants.PARAM_MODAL] !== undefined) {
            return {
              modal: params[RouteParamConstants.PARAM_MODAL],
            };
          } else {
            return {};
          }
        })(),
        // Control whether to hide/show based on new param state
        showModal: params[RouteParamConstants.PARAM_MODAL] === undefined ? false : true,
      });
    }
    if (!Utils.isEmpty(params[RouteParamConstants.PARAM_EXTERNAL_URL])) {
      window.open(params[RouteParamConstants.PARAM_EXTERNAL_URL], '_blank');
      QueryParamProxy.setQueryParams({
        [RouteParamConstants.PARAM_EXTERNAL_URL]: undefined,
      });
    }
  };

  overlayVisible = () =>
    (this.state.isBlackLayoutVisible && this.props.animationFeature) || this.props.isMissionsTutorialFirstStep;

  render() {
    const themeContext = this.context;
    return (
      <SLobbyOverlay
        initialModal={this.state.modal}
        {...this.props}
        render={({
          descriptor,
          loading,
          dismissModal,
          notifications,
          navState,
          showTooltip,
          tooltipDetails,
          showCustomModal,
          updatedTerms,
        }) => {
          return (
            <>
              <BlackOverlay isVisible={this.overlayVisible()} />
              <TimestampManagerComponent />
              {loading && !updatedTerms && <ActivityIndicator />}

              {!loading && !updatedTerms && (
                <>
                  <NavigationBar
                    navigation={this.props.navigation}
                    isCoinsGlowAnimationStopped={this.state.isCoinsGlowAnimationStopped}
                    isCoinsGlowAnimationEnabled={this.props.animationFeature}
                  />
                  {navState !== NavigationConstants.HIDE_BOTTOM && <SideMenu navigation={descriptor.navigation} />}
                  <MainSceneContainer
                    bottomState={NavigationConstants.HIDE_BOTTOM}
                    theme={themeContext.LobbyOverlay.MainSceneContainer}
                    className={'SceneContainer' + (NavigationConstants.HIDE_BOTTOM ? ' hideBottomBar' : '')}
                    pointerEvents={tutorialPointerEvents(this.props.blockLobby)}
                  >
                    {!Utils.isEmpty(this.props.lobbyPromotions) && navState === NavigationConstants.NO_HIDE && (
                      <PinnedPanelContainer
                        theme={themeContext.LobbyOverlay.PinnedPanelContainer}
                        slides={this.props.lobbyPromotions?.length}
                      >
                        <PinnedPanel panelList={this.props.lobbyPromotions} navigation={this.props.navigation} />
                      </PinnedPanelContainer>
                    )}
                    <SceneView component={descriptor.getComponent()} navigation={descriptor.navigation} />
                  </MainSceneContainer>
                  <BottomBar
                    navigation={this.props.navigation}
                    startAnimationCallback={this.startTimedBonusAnimation}
                  />
                </>
              )}

              {updatedTerms && <UpdateTerms />}

              {notifications.toasts && notifications.toasts.length > 0 && <Toast data={notifications.toasts[0]} />}
              {!updatedTerms && (
                <LobbyModal
                  modal={this.state.modal}
                  showModal={this.state.showModal}
                  dismissModal={dismissModal}
                  navigation={this.props.navigation}
                  theme={themeContext.LobbyOverlay}
                  showCustomModal={showCustomModal}
                />
              )}
              {showTooltip && tooltipDetails && <LobbyTooltip tooltipDetails={tooltipDetails} />}
            </>
          );
        }}
      />
    );
  }
}

LobbyOverlay.contextType = ThemeContext;

