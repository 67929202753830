// LobbyModal.jsx
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ImageBW from '../../../components/ImageBoundsWrapper';
import Animated from 'animated/lib/targets/react-dom';
import { ModalFooterText } from './../styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { isNotStandaloneMobilePWA, pwaStyleAdapter } from '../../../utils/mobileSupported';
import { checkModalBlackList } from './../utils';
import modalComponents from '../../modals';
import audio from 'shared/utils/audio';
import { useDispatch, useSelector } from 'shared/node_modules/react-redux';
import { setPromoActionRunning } from 'shared/state/slices/actionCommands/actionCommands';
import QueryParamProxy from 'shared/utils/queryParamProxy';
import styled from 'styled-components';
import ImageBoundsWrapper from '../../../components/ImageBoundsWrapper';
import theme from 'shared/assets/style/theme';
import { getLinkTextClientColor } from 'shared/utils/linkTextsColors';
import { onPressHelp } from 'shared/utils/redirectionHelp';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const LobbyModal = ({ modal, showModal, dismissModal, navigation, theme, showCustomModal }) => {
  const dispatch = useDispatch();
  const [popUpOpened, setPopUpOpened] = useState(false);
  const promoActionRunning = useSelector(state => state.actionCommands.promoActionRunning);

  const getModalComponent = () => {
    if (modal === null || modal === undefined) return false;

    const Component = modalComponents[modal];
    if (!Component) throw new Error(`Unsupported Modal Type "${modal}"`);
    if (showModal && !popUpOpened) {
      if (modal) audio.playSfx('popUp', true, 1);
      else audio.playSfx('popUp');
      setPopUpOpened(true);
    }
    return Component;
  };

  const ModalComponent = getModalComponent();

  const dismissModalGenerator = () => {
    return () => {
      audio.onClick();
      if (popUpOpened) {
        if (modal) audio.playSfx('popUpReverse', true, 1);
        else audio.playSfx('popUpReverse');
        setPopUpOpened(false);
      }
      let modalHandlerResponse = false;
      if (ModalComponent.DismissHandler) {
        modalHandlerResponse = ModalComponent.DismissHandler();
      } else {
        dismissModal();
      }
      const dismissPromoAction = ModalComponent.AsModalProps
        ? ModalComponent.AsModalProps.dismissPromoAction
        : undefined;
      if (dismissPromoAction && promoActionRunning && promoActionRunning === dismissPromoAction) {
        dispatch(setPromoActionRunning(null));
      }
      QueryParamProxy.modalPromiseAccept(modalHandlerResponse);
      return true;
    };
  };

  let modalProps = {
    onHide: dismissModalGenerator(),
    size: 'lg',
    'aria-labelledby': 'contained-modal-title-vcenter',
    centered: true,
    ...ModalComponent.AsModalProps,
  };

  const modalStyle =
    isNotStandaloneMobilePWA && !checkModalBlackList(modal) ? theme.MobileModalDefaultStyles : modalProps.style || {};

  return (
    <Modal show={showModal} {...modalProps} style={modalStyle}>
      <>
        {!ModalComponent.hideDefaultBackground && (
          <div
            className={`ModalBackground${
              ModalComponent.isTabbed ? ' Tabbed' : ''
            }${ModalComponent.hideBeforeSelector || modalProps.hideBeforeSelector ? ' hideBefore' : ''}`}
          >
            {showCustomModal ? (
              <ImageBW
                className="ModalBackgroundAsset"
                as={Animated.div}
                source={assetSource(ASSET_KEY.MODAL_BACKER)}
                ibw={require(asset`modal-backing-9-slice.ibw`)}
                widthScale={0.5}
              />
            ) : (
              <ImageBW
                className="ModalBackgroundAsset"
                as={Animated.div}
                source={assetSource(ASSET_KEY.MODAL_BACKING_9_SLICE_WEB)}
                ibw={require(asset`modal-backing-9-slice.ibw`)}
                widthScale={0.5}
              />
            )}
          </div>
        )}
        {!ModalComponent.hideCloseButton && (
          <div className="ModalCloseButton" onClick={dismissModalGenerator()}>
            <ImageBW
              source={assetSource(ASSET_KEY.CLOSE_BUTTON)}
              ibw={require(asset`close-button.ibw`)}
              manualAssetScale={3}
              style={{
                objectFit: 'contain',
                height: '100%',
                width: '100%',
              }}
              innerStyle={{
                cursor: 'pointer',
              }}
              alt="Close"
            />
          </div>
        )}
        {ModalComponent.defaultHeader && (
          <Modal.Header>
            <HeaderImage
              source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
              ibw={require(asset`full-modal-header.ibw`)}
              style={{ position: 'absolute' }}
            >
              <HeaderContent>
                <TitleHolder>
                  <Title>{ModalComponent.title}</Title>
                </TitleHolder>
                {ModalComponent.helpButton && (
                  <HelpTextContainer onClick={onPressHelp}>
                    <LinkText customStyle={NeedHelpStyle} underline={false}>
                      {getText(TEXT_KEY.NEED_HELP)}
                    </LinkText>
                  </HelpTextContainer>
                )}
              </HeaderContent>
            </HeaderImage>
          </Modal.Header>
        )}
        <Modal.Body>
          <ModalComponent dismissModal={dismissModalGenerator()} navigation={navigation} />
        </Modal.Body>
        {ModalComponent.footer && (
          <ModalFooterText>
            {typeof footer === 'function' ? ModalComponent.footer() : ModalComponent.footer}
          </ModalFooterText>
        )}
      </>
    </Modal>
  );
};

export default LobbyModal;

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5vmin;
  top: 1em;
`;

const HeaderImage = styled(ImageBoundsWrapper)`
  height: 70px;
  width: 100%;
  object-fit: contain;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
`;

const TitleHolder = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 5vmin;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
`;

const Title = styled.p`
  font-size: ${pwaStyleAdapter(29, 'px')};
  font-weight: 600;
  color: ${theme.palette.common[1]};
  text-transform: uppercase;
  margin: 0;
`;

const HelpTextContainer = styled.div`
  cursor: pointer;
  z-index: 1;
`;

const NeedHelpStyle = {
  fontSize: pwaStyleAdapter(15),
  fontWeight: 600,
  textAlign: 'right',
  margin: '0',
};

const LinkText = styled.p`
  text-decoration: underline;
  color: ${getLinkTextClientColor};
  cursor: pointer;
  pointer-events: auto;
  ${({ customStyle }) => customStyle};
`;

