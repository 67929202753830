import React from 'react';
import Tooltip from '../../../components/Tooltip';

const LobbyTooltip = ({ tooltipDetails }) => {
  return (
    <Tooltip
      visible
      style={tooltipDetails.style ? tooltipDetails.style : {}}
      arrowStyle={tooltipDetails.arrowStyle ? tooltipDetails.arrowStyle : {}}
      containerStyle={tooltipDetails.containerStyle ? tooltipDetails.containerStyle : {}}
      tipStyle={tooltipDetails.tipStyle ? tooltipDetails.tipStyle : {}}
      source={tooltipDetails.source ? tooltipDetails.source : null}
      ibw={tooltipDetails.ibw ? tooltipDetails.ibw : null}
      imageStyle={tooltipDetails.gameImageStyle ? tooltipDetails.gameImageStyle : {}}
      titleStyle={tooltipDetails.titleStyle ? tooltipDetails.titleStyle : {}}
      subTitleStyle={tooltipDetails.subTitleStyle ? tooltipDetails.subTitleStyle : {}}
      iconType={tooltipDetails.iconType ? tooltipDetails.iconType : null}
      displayText={tooltipDetails.title}
      displayTextStyle={tooltipDetails.displayTextStyle}
      subTitle={tooltipDetails.subTitle}
      actionHandler={tooltipDetails.actionHandler}
      dividerStyle={tooltipDetails.dividerStyle ? tooltipDetails.dividerStyle : {}}
      animationClassName={tooltipDetails.animationState}
      popUpType={tooltipDetails.popUpType}
      innerStyle={tooltipDetails.innerStyle ?? {}}
      bottom={tooltipDetails.bottom}
      showArrow={tooltipDetails?.showArrow}
    />
  );
};

export default LobbyTooltip;
